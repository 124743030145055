import React, { useEffect, useState, useCallback } from "react"
import PropTypes from "prop-types"
import tw, { styled } from "twin.macro"
import Button from "@elements/Button"
import Typewriter from "typewriter-effect"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { motion, AnimatePresence } from "framer-motion"
import { useInView } from "react-intersection-observer"
import parse from "html-react-parser"
import cursorRight from "@images/cursor-right.png"
import cursorLeft from "@images/cursor-left.png"

const Section = styled.section`
  ${tw`relative z-10 flex flex-col pt-10 pb-20 px-offset md:px-offsetMd lg:px-offsetLg`}

  & .typing-heading {
    ${tw`relative z-10 font-black uppercase text-54 sm:text-80/80 2xl:text-100/100 text-blue font-ubuntu`}
  }
  & .copy {
    ${tw`pt-0`}
  }
  .content {
    & .Typewriter__cursor {
      ${tw`opacity-0 text-54 sm:text-80/80 2xl:text-100/100`}
    }
    & .Typewriter {
      ${tw`relative py-6`}
    }

    &--double {
      & > .Typewriter {
        ${tw`py-0`}

        &:nth-of-type(1) {
          ${tw`pt-6`}
        }
        &:nth-of-type(2) {
          ${tw`pb-6`}
        }
      }
    }
  }
`
const Grid = styled.div`
  ${tw`grid grid-cols-none mt-auto md:grid-cols-12`}
`
const ButtonColumn = styled.div`
  ${tw`md:col-span-4 lg:col-span-6 md:col-start-9 lg:col-start-7`}
`
const StepsNav = styled.div`
  ${tw`z-40 lg:w-1/2 pt-c25 md:pt-0 md:absolute md:top-6 md:pl-4 left-2/3 lg:left-1/2 text-12 font-ubuntu`}
  & .nav-item {
    transform-origin: center center;
    ${tw`relative block outline-none focus:text-black uppercase cursor-pointer text-20 xl:text-24 mb-1.5 font-bold ml-8 text-lightGrey`}

    &::before {
      content: "";
      ${tw`absolute w-3 h-3 pr-3 transition duration-500 ease-in-out transform -translate-y-1/2 border rounded-full -left-8 md:w-4 md:h-4 border-lightGrey top-1/2`}
    }

    &.active {
      ${tw`text-blue`}

      &::after {
        content: "";
        padding-bottom: 2px;
        height: 2px;
        ${tw`absolute md:pb-0.5 md:h-0.5 left-0 right-0 w-full bg-blue bottom-0`};
      }
      &::before {
        ${tw`border-yellow bg-yellow`}
      }
    }
    &.flash {
      animation: bounce 0.6s;
      animation-iteration-count: 3;
    }
  }

  @keyframes bounce {
    0%,
    100% {
      transform: translateY(-15%);
      animationtimingfunction: cubic-bezier(0.8, 0, 1, 1);
      ${tw`text-black`}
    }
    50% {
      transform: translateY(0);
      animationtimingfunction: cubic-bezier(0, 0, 0.2, 1);
    }
  }
`

const WpAcfTypeWritingModuleBlock = ({ moduleData }) => {
  const blockData = moduleData.acfTypeWritingBlock
  const sectionAttributes = moduleData.attributes
  const sectionId = sectionAttributes.anchor || "withus"
  const { ref, inView } = useInView({
    threshold: 0.5,
  })
  const [activeStep, setActiveStep] = useState("step2")
  const [navFlashed, setNavFlashed] = useState(false)
  const [loadedOnce, setLoadedOnce] = useState(false)
  const stepBlockMotion = {
    init: {
      opacity: 0,
    },
    open: {
      opacity: 1,
      transition: {
        duration: 0.5,
      },
    },
  }
  const flashStepNav = () => {
    const flashNavElement = document.querySelector(".nav-item__flash")
    if (navFlashed === false) {
      setNavFlashed(true)
      flashNavElement.classList.add("flash")
    }
  }

  const customCursorHandle = e => {
    const section = document.getElementById(sectionId)
    const width =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth
    const targetable =
      e.target.classList.contains("nav-item") ||
      e.target.classList.contains("steps-nav")
    const posY = e.clientY - section.getBoundingClientRect().top
    if (e.clientX > width / 2 && posY > 200 && !targetable) {
      section.style.cursor = `url(${cursorRight}), auto`
    }
    if (e.clientX < width / 2 && posY > 200 && !targetable) {
      section.style.cursor = `url(${cursorLeft}), auto`
    }
    if (targetable || posY < 200) {
      section.style.cursor = `default`
    }
  }
  const changeSteps = useCallback(
    (e, target) => {
      const section = document.getElementById(sectionId)
      const width =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth
      const posY = e.clientY - section.getBoundingClientRect().top
      if (
        target.tagName !== "A" &&
        target.tagName !== "BUTTON" &&
        e.clientX > width / 2 &&
        posY > 200
      ) {
        setActiveStep(currentActiveStep => {
          if (currentActiveStep === "step5") return "step2"
          return `step${parseInt(currentActiveStep.split("step")[1]) + 1}`
        })
      }
      if (
        target.tagName !== "A" &&
        target.tagName !== "BUTTON" &&
        e.clientX < width / 2 &&
        posY > 200
      ) {
        setActiveStep(currentActiveStep => {
          if (currentActiveStep === "step2") return "step5"
          return `step${parseInt(currentActiveStep.split("step")[1]) - 1}`
        })
      }
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )
  useEffect(() => {
    if (inView && activeStep === "step2") {
      window.addEventListener("scroll", flashStepNav)
      setLoadedOnce(true)
    }
    if (activeStep === "step3") {
      window.removeEventListener("scroll", flashStepNav)
    }
    return () => window.removeEventListener("scroll", flashStepNav)
  }, [activeStep, inView])

  const step2Image = getImage(blockData.step2Image?.localFile)
  const step4Image = getImage(blockData.step4Image?.localFile)

  return (
    <Section
      id={sectionId}
      onMouseMove={customCursorHandle}
      onClick={e => changeSteps(e, e.target)}
      className="with-us min-h-[750px] max-h-[1080px]"
      ref={ref}
    >
      <p className="relative z-10 w-1/2 overline-section text-blue md:text-right">
        {parse(blockData.sectionOverline)}
      </p>
      <div className="custom-cursor w-full min-h-[750px] max-h-[1080px] h-full absolute grid grid-cols-2">
        <div></div>
        <div></div>
      </div>
      <AnimatePresence exitBeforeEnter>
        <motion.div className="relative with-us__body" key="steps-motion">
          <StepsNav className="steps-nav">
            <button
              className={`nav-item ${activeStep === "step2" ? "active" : ""}`}
              onClick={() => setActiveStep("step2")}
            >
              Erste liga
            </button>
            <button
              className={`nav-item nav-item__flash ${
                activeStep === "step3" ? "active" : ""
              }`}
              onClick={() => setActiveStep("step3")}
            >
              Kundenmagnet
            </button>
            <button
              className={`nav-item ${activeStep === "step4" ? "active" : ""}`}
              onClick={() => setActiveStep("step4")}
            >
              Dauerbrenner
            </button>
            <button
              className={`nav-item ${activeStep === "step5" ? "active" : ""}`}
              onClick={() => setActiveStep("step5")}
            >
              Wow
            </button>
          </StepsNav>
          {(inView || loadedOnce) && activeStep === "step2" && (
            <motion.div
              variants={stepBlockMotion}
              initial="init"
              animate="open"
              className="step2"
            >
              <div className="grid grid-cols-12 gap-4">
                <div className="flex flex-col items-end col-span-12 content md:col-span-8 lg:col-span-6">
                  <Typewriter
                    options={{
                      cursor: "",
                      wrapperClassName: "typing-heading text-right",
                    }}
                    onInit={typewriter => {
                      typewriter.typeString("ERSTE LIGA").start()
                    }}
                  />
                </div>
                <div className="flex justify-end col-span-12 columns md:mt-10">
                  <div className="top-0 left-0 grid w-full gap-2 wrap md:grid-cols-12">
                    <GatsbyImage
                      objectFit="contain"
                      className="!hidden md:!block absolute left-0 bottom-0 w-1/2 md:w-auto sm:col-span-4 sm:relative"
                      image={step2Image}
                      alt={blockData.step2Image?.altText}
                    />
                    <div className="relative z-10 copy sm:px-offset md:col-span-8 lg:col-span-6">
                      <p>{parse(blockData.step2Description)}</p>
                      <Button
                        buttonText={parse(blockData.sectionButton?.title)}
                        buttonHref={blockData.sectionButton.url}
                        buttonClasses="bg-yellow text-black mr-auto"
                        buttonTarget={blockData.sectionButton.target}
                      />
                    </div>
                  </div>
                </div>
                <div className="relative w-full columns"></div>
              </div>
            </motion.div>
          )}
          {activeStep === "step3" && (
            <motion.div
              variants={stepBlockMotion}
              initial="init"
              animate="open"
              className="step3"
            >
              <div className="grid grid-cols-12 gap-4">
                <div className="col-span-12 content content--double md:col-span-8 lg:col-span-6">
                  <Typewriter
                    options={{
                      cursor: "",
                      wrapperClassName: "typing-heading !absolute right-0",
                    }}
                    onInit={typewriter => {
                      typewriter.changeDelay(100).typeString("Kunden-").start()
                    }}
                  />
                  <Typewriter
                    options={{
                      cursor: "",
                      wrapperClassName: "typing-heading !absolute right-0",
                    }}
                    onInit={typewriter => {
                      typewriter
                        .stop()
                        .changeDelay(100)
                        .pauseFor(800)
                        .typeString("magnet")
                        .start()
                    }}
                  />
                  <div
                    className="big-symbol block font-firacode font-extrabold absolute text-yellow top-1/2 transform -translate-y-1/2 -translate-x-80 whitespace-nowrap text-[250px] md:text-[430px]"
                    style={{
                      zIndex: "-1",
                    }}
                  >
                    {`->>|<<-`}
                  </div>
                  <div className="flex justify-end columns">
                    <div className="copy md:w-3/4">
                      <p>{parse(blockData.step3Description)}</p>
                      <Button
                        buttonText={parse(blockData.sectionButton?.title)}
                        buttonHref={blockData.sectionButton.url}
                        buttonClasses="bg-yellow text-black mr-auto"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>
          )}
          {activeStep === "step4" && (
            <motion.div
              variants={stepBlockMotion}
              initial="init"
              animate="open"
              className="step4"
            >
              <div className="relative grid grid-cols-12 gap-4">
                <div className="flex flex-col items-end col-span-12 content content--double md:col-span-8 lg:col-span-6">
                  <Typewriter
                    options={{
                      cursor: "",
                      wrapperClassName: "typing-heading !absolute right-0",
                    }}
                    onInit={typewriter => {
                      typewriter.changeDelay(100).typeString("Dauer-").start()
                    }}
                  />
                  <Typewriter
                    options={{
                      cursor: "",
                      wrapperClassName: "typing-heading !absolute right-0",
                    }}
                    onInit={typewriter => {
                      typewriter
                        .stop()
                        .changeDelay(100)
                        .pauseFor(800)
                        .typeString("Brenner")
                        .start()
                    }}
                  />
                  <GatsbyImage
                    objectFit="contain"
                    className="!hidden md:!block !absolute bottom-0 w-1/2 sm:w-1/3 md:w-auto md:bottom-auto md:top-20 left-0 z-0"
                    image={step4Image}
                    alt={blockData.step4Image?.altText}
                  />
                </div>
                <div className="flex justify-end col-span-12 columns">
                  <div className="relative mx-auto copy md:w-2/3 lg:w-3/5">
                    <p>{parse(blockData.step4Description)}</p>
                    <Button
                      buttonText={parse(blockData.sectionButton?.title)}
                      buttonHref={blockData.sectionButton.url}
                      buttonClasses="bg-yellow text-black mr-auto"
                    />
                  </div>
                </div>
              </div>
            </motion.div>
          )}
          {activeStep === "step5" && (
            <motion.div
              variants={stepBlockMotion}
              initial="init"
              animate="open"
              className="step5"
            >
              <div className="grid grid-cols-12 gap-4">
                <div className="flex flex-col items-end col-span-12 content md:col-span-8 lg:col-span-6">
                  <div
                    className="absolute block font-extrabold transform rotate-90 -translate-y-1/2 big-symbol font-firacode text-yellow top-1/5 whitespace-nowrap"
                    style={{
                      fontSize: "430px",
                      zIndex: "-1",
                    }}
                  >
                    {`{}`}
                  </div>
                  {blockData.step5Video !== null && (
                    <div className="hidden md:flex absolute -bottom-6 w-2/3 sm:w-1/3 left-0 z-0 max-w-[360px] h-[360px] items-end">
                      <video
                        autoPlay
                        muted
                        playsInline
                        src={blockData.step5Video.localFile.publicURL}
                        className="w-full"
                      ></video>
                    </div>
                  )}
                  <Typewriter
                    options={{
                      cursor: "",
                      wrapperClassName: "typing-heading text-right",
                    }}
                    onInit={typewriter => {
                      typewriter.typeString("WOW").start()
                    }}
                  />
                </div>
                <div className="flex justify-end col-span-12 columns md:mt-10">
                  <div className="relative mx-auto copy md:w-1/2">
                    <p>{parse(blockData.step5Description)}</p>
                    <Button
                      buttonText={parse(blockData.sectionButton?.title)}
                      buttonHref={blockData.sectionButton.url}
                      buttonClasses="bg-yellow text-black mr-auto"
                    />
                  </div>
                </div>
              </div>
            </motion.div>
          )}
        </motion.div>
      </AnimatePresence>
      <Grid>
        <ButtonColumn></ButtonColumn>
      </Grid>
    </Section>
  )
}

WpAcfTypeWritingModuleBlock.propTypes = {
  moduleData: PropTypes.object,
}

export default WpAcfTypeWritingModuleBlock
